<template>
	<div>
		<transition name="fade">
			<Message v-if="error" :message="error" type="error" />
		</transition>

		<transition name="fade">
			<Message v-if="message" :message="message" type="info" />
		</transition>

		<transition name="fade">
			<Message v-if="statusMessage" :message="statusMessage" type="info" />
		</transition>

		<InputField
			v-model="email"
			name="Email"
			type="email"
			:required="isRequired('email')"
			:disabled="status === 1"
			@submit="update"
		>
			<template #note>
				If you already have an account for this service, please enter
				the email associated with that account and ensure that you write
				it down in case you require an admin to create an account.
			</template>
		</InputField>

		<SubmitButton :disabled="status === 1" @click="update">
			Update
		</SubmitButton>
	</div>
</template>

<script>
import store from "@/store";
import api from "@/api";

import InputField	from "@/components/InputField.vue";
import SubmitButton	from "@/components/SubmitButton.vue";
import Message	from "@/components/Message.vue";

export default {
	name: "Update",

	components: {
		InputField,
		SubmitButton,
		Message
	},

	data () {
		return {
			email: "",
			error: undefined,
			message: null,
			status: 0
		};
	},

	computed: {
		statusMessage () {
			switch (this.status) {
				case 1:
					return "Updating Email.";
				case 2:
					return "Email verification sent! Please check your emails.";
				default:
					return "";
			}
		}
	},

	beforeMount () {
		if (store.state.status === "requires")
			this.message = `${store.state.service} requires additional information before login.`;
	},

	methods: {
		async update () {
			this.status = 0;

			try {
				this.status++;
				this.error = undefined;

				await api.update(this.email);

				this.status++;
			} catch (e) {
				console.error(e);

				this.status = 0;

				if (e.errcode)
					return this.error = e.data.error;

				this.error = e.message ? e.message : e;
			}
		},

		isRequired (name) {
			return store.state.requires.includes(name);
		}
	}
};
</script>
